(function () {
  'use strict';

  class ResourcesRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    resources() {
      return this.$resource(`${this.env.api}/resources`);
    }

    myResources() {
      return this.$resource(`${this.env.api}/resources/my-resources`);
    }

    resource() {
      return this.$resource(`${this.env.api}/resources/:id/:clone`, {id: '@resource.id', clone: '@resource.clone'}, {update: {method: 'PUT'}});
    }

    polls() {
      return this.$resource(`${this.env.api}/resources/:id/polls/:poll_id/:question_id/:options/:option_id`, {id: '@id', poll_id: '@poll_id', question_id: '@question_id', options: '@options', option_id: '@option_id'}, {update: {method: 'PUT'}});
    }

    lomMetadata() {
      return this.$resource(`${this.env.api}/resources/:id/lom-metadata`, {id: '@id'});
    }

  }

  /**
   * @ngdoc service
   * @name components.service:ResourcesRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('ResourcesRest', ResourcesRest);
}());
